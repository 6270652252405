@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

* {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.App {
  text-align: center;
}