

.eMed_Login_Main_Box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: 100vh; */
    overflow: hidden;
}


h2 {
    font-size: 3vw;
    font-weight: 600;
    text-align: center;
    margin-top: 2vw;
}

.cssanimation,
.cssanimation span {
    animation-duration: 4s;
    animation-fill-mode: both;
}


.open {
    width: 14.5vw;
    margin: 0 auto;
    overflow: hidden;
    animation-name: open;
}

@keyframes open {
    from {
        width: 0
    }
}

.eMed_Login_Left_Box {
    position: relative;
    width: 150%;
    /* Adjust to fit your container width */
    height: 100vh;
    /* Adjust to fit your container height */
    overflow: hidden;

}

.emd_image {
    /* position: absolute; */
    /* width: 100%; */
    height: 140vh;
    animation: scroll 15s linear infinite;
}

.eMed_Login_Right_Box {
    margin-top: 3vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@keyframes scroll {
    0% {
        transform: translateY(-10%);
    }

    50% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-10%);
    }
}

.blinking-cursor {
    font-size: 3vw;
    /* Adjust as needed */
    animation: blink 1s step-start infinite;
    font-weight: 300;
    margin-top: 0.8vw;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.eMed_Login_Right_Text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
    width: 15vw;
}

.eMed_Login_Right_Container{
    height: 50vh;
    width: 30vw;
    margin-top: 2vw;
    border: 1px solid #04B7B1;
    border-radius: 4px;
    box-shadow: 0 2px 6px #04B7B1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}