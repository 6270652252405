.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .OverAll {
    border: 5px solid #17a2b8;
    border-radius: 8px;
    padding: 20px;
  }
  
  .GenderHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .header {
    margin-top: -15px;
  }
  
  .Title {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  }
  
  .GenderLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .GenderLabel div {
    margin-top: 4px;
  }
  
  .rowOne {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
  }
  
  .rowOneSub {
    display: flex;
    flex-direction: column;
    width: 47%;
  }
  
  .formPageDetails input {
    height: 25px;
    text-indent: 0;
    font-size: 14px;
    width: 100%;
  }
  
  .phoneInput {
    width: -webkit-fill-available;
    height: 42px !important;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: 14px;
  }
  
  .phoneInput:focus {
    border: 1px solid #17a2b8 !important;
    outline: none
  }
  
  span.countryCode {
    padding: 10px;
    font-size: 16px;
    background-color: #f1f1f1;
    border: 1px solid #bab3b3;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 42px;
  }
  
  .GenderOptions {
    display: flex;
    flex-wrap: wrap;
  }
  
  .textField {
    width: 100%;
    margin-top: 5px;
  }
  
  .labels {
    color: #17a2b8;
    font-size: 14px !important;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .datePicker {
    height: 42px !important;
    border: 1px solid #bab3b3;
    border-radius: 3px;
    width: 100%;
    font-size: 14px;
  }
  
  .datePicker:focus {
    border: 1px solid #17a2b8 !important;
    outline: none
  }
  
  .addressBar {
    height: 42px !important;
    border: 1px solid #bab3b3;
    border-radius: 3px;
    width: 100%;
    font-size: 14px;
  }
  
  .addressBar:focus {
    border: 1px solid #17a2b8 !important;
    outline: none
  }
  
  .submitbutton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .submitbutton input {
    height: auto;
    width: max-content;
    padding: 9px 35px;
    color: white;
    background-color: #17a2b8;
    cursor: pointer;
    border-radius: 20px;
    font-size: 15px;
    border: 1px solid whitesmoke;
  }
  
  .termspara span {
    font-size: 13px;
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  
  @media (max-width: 770px) {
  
    .formpage.container {
      width: 99%;
      margin: 0;
    }
  
    .rowOne {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  
    .rowOneSub {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .GenderHeading {
      margin-top: 15px;
      width: 200%;
    }
  
    .age {
      margin-top: 15px;
    }
  
    .address {
      margin-top: 15px;
    }
  
    .email {
      margin-top: 15px;
    }
  }