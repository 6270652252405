.container {
    width: 100%;
    margin: 0 auto !important;
    overflow-y: auto;
}

.overAllcontainer {
    background-color: #FCFAFB;
    border: 5px solid #FF01A1;
    border-radius: 8px;
    padding: 20px;
}

.GenderHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.shiv_dobDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 10px;
}

.header {
    margin-top: -15px;
}

.shiv_title {
    width: 100%;
    background-color: #f5cee4;
    color: #FF01A1;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.GenderLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GenderLabel div {
    margin-top: 4px;
}

.shiv_row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 20px;
    padding: 10px;
}

.shiv_rowSub {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formPageDetails input {
    height: 25px;
    text-indent: 0;
    font-size: 14px;
    width: 100%;
}

.phoneInput {
    width: -webkit-fill-available;
    height: 42px !important;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: 14px;
}

.phoneInput:focus {
    border: 1px solid #f5cee4 !important;
    outline: none
}

span.countryCode {
    padding: 10px;
    font-size: 16px;
    background-color: #f1f1f1;
    border: 1px solid #bab3b3;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 42px;
}

.shiv_GenderOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
}

.textField {
    width: 100%;
    margin-top: 5px;
}

.shiv_labels {
    color: #FF01A1;
    font-size: 13.23px !important;
    font-weight: 500;
    margin-bottom: 5px;
    white-space: nowrap;
}

.datePicker {
    height: 42px !important;
    border: 1px solid #bab3b3;
    border-radius: 3px;
    width: 100%;
    font-size: 14px;
}

.datePicker:focus {
    border: 1px solid #f5cee4 !important;
    outline: none
}

.addressBar {
    height: 42px !important;
    border: 1px solid #bab3b3;
    border-radius: 3px;
    width: 100%;
    font-size: 14px;
}

.addressBar:focus {
    border: 1px solid #f5cee4 !important;
    outline: none
}

.shiv_submitbutton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.shiv_submitbutton input {
    height: auto;
    width: max-content;
    padding: 9px 35px;
    color: white;
    background-color: #FF01A1;
    cursor: pointer;
    border-radius: 20px;
    font-size: 15px;
    border: 1px solid whitesmoke;
}

.termspara span {
    font-size: 13px;
}

@media (max-width: 770px) {

    .formpage.container {
        width: 99%;
        margin: 0;
    }

    .shiv_row {
        grid-template-columns: 1fr;
    }

    .GenderHeading {
        margin-top: 15px;
        width: 200%;
    }

    .address,
    .email {
        margin-top: 15px;
    }
}

@media (min-width: 770px) and (max-width: 1199px) {
    .formpage.container {
        width: 90%;
    }

    .shiv_row {
        grid-template-columns: 1fr 1fr;
    }

}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}