.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

*,
::after,
::before {
    box-sizing: border-box;
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.label {
    font-size: 16px !important;
    font-weight: 500;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.countrycodediv {
    display: flex;
    align-items: center;
    width: 100% !important;
    margin-top: 7px;
}

span.country-code {
    padding: 10px;
    font-size: 16px;
    background-color: #f1f1f1;
    border: 1px solid grey;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 45px;
}

.countrycodediv input {
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.Gender {
    /* display: flex;
    gap: 10px; */
    align-items: center;
}

.formpagedetails {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}

span.tandcall {
    display: flex;
    gap: 5px;
    align-items: center;
}

.checkboxlist {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 1vw;
}

.checkboxlast {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.checkboxlast textarea {
    border-bottom: 1px solid black !important;
    border: none;
    height: 17px;
    margin-left: 20px;
    text-transform: capitalize;
    font-size: 16px;
    width: 55%;
}

.source {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.source label {
    margin-left: 0.35vw;
}

.emailid input {
    text-transform: unset !important;
}

.emailid {
    position: relative;
}

p.error {
    color: Red;
    font-weight: 500;
    font-size: 14px;
}

.header {
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.Footer {
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.header img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.Footer img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

p.termsandconditionshow {
    cursor: pointer;
    margin: 0;
}

.formpage.container {
    width: 50%;
    box-shadow: 0px 0px 10px 1px #dcd8d8;
    padding: 15px 2rem;
    margin: 2rem auto;
}

.address textarea {
    width: -webkit-fill-available;
    height: 45px;
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 5px;
}

.formpagedetails input[type="text"] {
    font-size: 14px;
    text-transform: capitalize;
    text-indent: 10px;
    width: 100%;
}

.formpagedetails input[type="number"] {
    font-size: 14px;
    text-indent: 10px;
    width: 100%;
}

.formpagedetails input {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: 14px;
    box-sizing: border-box;
}

.Gender input {
    height: max-content !important;
    width: max-content !important;
}

.checkboxlist input {
    height: 15px !important;
    width: 15px;
}

.checkboxlast input {
    width: 15px;
}

span.tandcall input {
    width: 15px;
    height: 20px;
}

.submitbtn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.submitbtn input {
    height: auto;
    width: max-content;
    padding: 9px 35px;
    color: white;
    background-color: black;
    cursor: pointer;
    border-radius: 20px;
}

.appstoreandplaystore {
    display: flex;
    align-items: center;
    justify-content: center;
}

.appstoreandplaystore img {
    width: 200px;
    height: max-content;
}

.Heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 25px;
}

.Input-Container {
    width: 100% !important;
    margin-top: 7px;
}

.Input-Container textarea{
    width: 100% !important;
}

.Input-Style {
    font-size: 12px;
}

/* input[type="text"]:focus,
.formpagedetails input[type="number"]:focus,
textarea:focus,
.dob input[type="date"]:focus {
    border-color: grey;
    box-shadow: 0 0 5px rgb(122 120 120 / 50%);
    outline: none;
} */

.checkboxlast textarea:focus {
    border-color: none !important;
    outline: none !important;
}

.formpagedetails label {
    text-transform: capitalize;
}

.name,
.labelofsex,
.dob,
.age,
.address,
.pincode,
.phonenum,
.emailid {
    width: 48%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.optionsofsex {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
}

.optionsofsex label {
    margin-left: 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.optionsofsex label {
    margin-left: 0.5vw;
}

p.erroragree {
    color: red;
    font-weight: 500;
    font-size: 14px;
    margin-left: 0.5vw;
}

p.error1 {
    position: absolute;
    right: 0;
    color: red;
    top: -15px;
    font-weight: 500;
}

p.success {
    display: grid;
    margin: auto;
    width: 100%;
    text-align: center;
    border: 1px solid green;
    padding: 18px 10px;
    font-size: 18px;
    font-weight: 700;
}

.howdidu {
    width: 100%;
}

.termsandcondition {
    width: 100%;
}

a.playstore img {
    width: 208px;
}

.emedlogo {
    margin: auto;
    text-align: center;
}

.emedlogo img {
    width: 55px;
}

.emedlogo p {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: #3f3e3e;
}

.imagesall {
    margin-top: 1rem;
}

p.howdidupara {
    font-size: 19px;
}

span.alreadypat {
    font-size: 17px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

label.labelofsex {
    display: block;
    width: -webkit-fill-available;
}


@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (max-width: 789px) {
    p.error {
        top: -30px;
    }

    .countrycodediv input {
        width: 100% !important;
    }

    p.howdidupara {
        margin-top: 0px;
        font-size: 14px;
    }

    p.termsandconditionshow {
        font-size: 14px;
    }

    .appstoreandplaystore {
        flex-wrap: wrap;
    }

    .appstoreandplaystore img {
        width: 100px;
    }

    a.playstore img {
        width: 105px;
    }

    .submitbtn {
        margin: auto;
    }

    .formpage.container {
        width: 99%;
        margin: 0;
    }

    .formpagedetails input {
        height: 35px;
    }

    .name,
    .Gender,
    .dob,
    .age,
    .address,
    .pincode,
    .phonenum,
    .emailid {
        width: 100%;
    }

    span.country-code {
        font-size: 14px;
        height: 35px;
        display: flex;
        align-items: center;
    }

    p.error1 {
        top: -32px;
        text-align: right;
    }
}